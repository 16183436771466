.auth-code__container {
    padding-right: 16px;
}

.auth-code__input {
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    margin-right: 12px;
    text-transform: uppercase;
    color: #494949;
    font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background: #fff;
    background-clip: padding-box;
    &:focus {
        appearance: none;
        outline: 0;
        box-shadow: 0 0 0 3px rgb(131, 192, 253);
    }
}

.auth-image__background {
    height: 100vh;
    width: 100%;
    object-fit: fill;
}

.auth-otp-progress {
    position: absolute !important;
    bottom: -15px;
    left: 0px;
    right: 0px;

    .ant-progress-inner {
        border-radius: 0px !important;
    }

    .ant-progress-bg {
        border-radius: 0px !important;
    }
}
