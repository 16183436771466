// .app-layout__wrapper {
//     height: 100vh;
//     overflow-y: hidden;
// }

$compact-menu-width: 50px;
$expand-menu-width: 300px;

$compact-main-width: calc(100vw - 50px);
$expand-main-width: calc(100vw - 300px);

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
body {
    overflow-y: hidden !important;
}

@mixin expanding-avatar {
    min-width: 85px !important;
    width: 85px !important;
    height: 85px;
    object-fit: cover;
    margin: 10px;
    border-radius: 15px;
    div.text-white {
        font-size: 40px;
        font-weight: 500;
    }
}

@mixin compact-avatar {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px;
    border-radius: 5px;
    div.text-white {
        font-size: 25px;
        font-weight: 500;
    }
}

@mixin user-expanding-info-view {
    .user-info-avatar {
        @include expanding-avatar();
    }
    text {
        display: inline-block !important;
    }
}

@mixin user-compact-info-view {
    display: flex;
    padding: 10px 0px 0px 0px;
    margin-bottom: 0px;
    .user-info-avatar {
        @include compact-avatar();
    }
    text {
        display: none !important;
    }
}

@mixin menu-compact-view {
    min-width: $compact-menu-width;
    max-width: $compact-menu-width;
    .app-layout__sidebar-header {
        max-width: $compact-menu-width;
        min-width: $compact-menu-width;
        padding: 5px !important;
    }
    .app-layout__menu-list {
        max-width: $compact-menu-width;
        min-width: $compact-menu-width;
        display: block;
        .app-layout__menu-list-item {
            display: flex;
            align-items: center;
            justify-content: center;
            .app-layout__menu-list-title,
            .app-layout__menu-list-icon-arrow {
                display: none;
            }
        }
    }
    .app-layout__sidebar-user-info {
        @include user-compact-info-view();
    }
    .app-layout__sub-menu-list {
        display: none;
    }
    .app-layout__sidebar-button-toggle {
        width: $compact-menu-width;
        .app-layout__sidebar-button-toggle-text {
            display: none;
        }
    }

    .app-layout__sidebar-header-logo {
        display: none;
    }

    .app-layout__sidebar-header-logo-icon {
        display: flex;
        width: 100%;
    }
}

@mixin menu-expanding-view {
    max-width: $expand-menu-width;
    min-width: $expand-menu-width;

    .app-layout__sidebar-header-logo {
        display: flex;
    }

    .app-layout__sidebar-header-logo-icon {
        display: none;
    }

    .app-layout__sidebar-header {
        max-width: $expand-menu-width;
        min-width: $expand-menu-width;
    }
    .app-layout__menu-list {
        max-width: $expand-menu-width;
        min-width: $expand-menu-width;
        .app-layout__menu-list-item {
            display: flex;
            flex-direction: column;
            justify-content: start;
            margin-top: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            i {
                color: $light-opposite-muted-color;
            }

            :hover {
                cursor: pointer;
            }
        }

        .app-layout__menu-list-item-inner {
            display: flex;
            width: 100%;
            align-items: center;
            cursor: pointer;
        }

        .app-layout__menu-list-item-active {
            background: rgba($color: #ffffff, $alpha: 0.3);

            i {
                color: $light-color;
            }

            .app-layout__menu-list-title {
                color: $light-color;
            }
        }

        .app-layout__sub-menu-item-active {
            .app-layout__menu-list-title {
                color: $light-color !important;
            }
        }

        .app-layout__menu-list-title {
            color: $light-opposite-muted-color;
            margin-left: 20px;
            flex: 1;
        }

        .app-layout__sub-menu-list {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .app-layout__sub-menu-item {
            padding: 10px;
            color: $light-opposite-muted-color;
            i {
                color: $dark-color;
            }
        }
        .app-layout__sub-menu-item {
            .app-layout__menu-list-title {
                display: flex !important;
                width: 100% !important;
            }
            text-align: start !important;
        }
    }
    .app-layout__sidebar-user-info {
        @include user-expanding-info-view();
    }
    .app-layout__menu-list-title,
    .app-layout__menu-list-icon-arrow {
        display: inline !important;
        text-align: start !important;
    }
}

#app-layout__content {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    width: $compact-main-width;
}

.app-layout__wrapper {
    background-color: whitesmoke !important;
    .app-layout__navbar {
        padding: 8px 16px 8px 4px;
    }
    #app-layout__sidebar {
        max-width: $expand-menu-width;
        min-width: $expand-menu-width;
        .app-layout__sidebar-header {
            max-width: $expand-menu-width;
            min-width: $expand-menu-width;
        }
        .app-layout__sidebar-user-info {
            .user-info-avatar {
                @include expanding-avatar();
            }
        }

        .app-layout__sub-menu-item:hover {
            background-color: rgba($color: #fff, $alpha: 0.2);
        }

        .app-layout__sidebar-header-logo {
            display: flex;
        }

        .app-layout__sidebar-header-logo-icon {
            display: none;
        }
    }
    #app-layout__content {
        height: 100% !important;
        overflow-y: scroll !important;
        background-color: transparent !important;
        width: $expand-main-width !important;
    }
    .app-layout__menu-list-item-sub-active {
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.app-layout__wrapper {
    #app-layout__sidebar.active {
        position: absolute;
        left: 0;
        min-width: $compact-menu-width;
        z-index: 9999;
        height: 100%;
        @include menu-compact-view();
    }
    #app-layout__content.active {
        background-color: transparent !important;
        width: $compact-main-width !important;
        margin-left: $compact-menu-width;
    }
}

.app-layout__wrapper {
    #app-layout__sidebar:hover {
        @include menu-expanding-view();
    }
}

.app-layout__wrapper {
    .mouse-enter#app-layout__content.active {
        background-color: transparent !important;
        width: $compact-main-width !important;
    }
    .mouse-enter#app-layout__content {
        background-color: transparent !important;
        width: $expand-main-width !important;
    }
}
