.rich-text-editor__is-view {
    .ql-toolbar.ql-snow {
        display: none;
    }
    .ql-container.ql-snow {
        border: 0px solid white;
        height: auto !important;
    }
}
.ql-editor p {
    padding-top: 1rem !important;
}

.rich-text-editor__large {
    .ql-container.ql-snow {
        height: 36rem;
    }
    .tox-edit-area {
        height: 500px !important;
    }
}
.rich-text-editor__medium {
    .ql-container.ql-snow {
        height: 15rem;
    }
    .tox-edit-area {
        height: 200px !important;
    }
}

.rich-text-editor__small {
    .ql-container.ql-snow {
        height: 10rem;
    }
    .tox-edit-area {
        height: 100px;
    }
}
.tox-browse-url {
    display: none !important;
}
