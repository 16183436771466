.worksheet-create__step-line {
    height: 5px;
    width: 100%;
}

.worksheet-create__step-muted {
    background-color: #e0e0e0;
}
.worksheet-create__step-text-muted {
    color: #e0e0e0;
}
