$link-color:                    #587C61                                 !default;
$primary-color:                 #587C61                                 !default;
$primary-color-15:              rgba(88, 124, 97, 0.15)                   !default;
$primary-color-30:              rgba(88, 124, 97, 0.3)                   !default;
$primary-color-50:              rgba(88, 124, 97, 0.5)                   !default;
$error-color-20:                rgba(244,67,54,0.2)                 !default;
$tab-bg:                        transparent                  !default;

@import "../../node_modules/d-react-components/dist/variables";

 