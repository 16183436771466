@import "../variables";

$height-image: 40px !important;

.customer-detail__info-avatar-container {
    position: relative;
    height: $height-image;
    width: $height-image;
    > img {
        height: $height-image;
        width: $height-image;
        border-radius: 20px;
        object-fit: cover;
    }
}

.address__search-container {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    width: 40%;
}
.address__pin-location {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
