$image-item-size: 9rem;

.product-create__gallery-images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.product-create__gallery-image-remove {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0px !important;
    color: $accent-color;
    cursor: pointer;
}

.product-create__gallery-video-icon {
    position: absolute;
    top: 40%;
    left: 40%;
    color: white;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.3);
}
